<template>
  <v-dialog
    style="z-index: 1000"
    v-model="dialog"
    width="80%"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    align="top"
  >
    <template v-slot:activator="{ on }">
      <v-btn v-bind="$attrs" icon v-on="on">
        <v-icon>{{ activatorIcon ? activatorIcon : "mdi-qrcode-scan" }}</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar>
        <v-toolbar-title> {{$vuetify.lang.t('$vuetify.label.scanQR') }} </v-toolbar-title>

        <v-menu v-if="cameras && cameras.length > 0" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-camera</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="switchCamera(camera)"
              v-for="camera in cameras"
              :key="camera.id"
            >
              <v-list-item-title>{{ camera.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-if="!cameras || cameras.length < 1"
          @click="getCameraPermission"
        >
          <v-icon>mdi-camera</v-icon>
        </v-btn>

        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-progress-linear v-if="loading" indeterminate />

      <v-card-text class="mt-5">
        <div id="qrScanner"></div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { decrypt } from "@/services/CryptoService.js";

import { mapActions, mapGetters } from "vuex";
import { Html5Qrcode, Html5QrcodeSupportedFormats } from "html5-qrcode";

export default {
  props: {
    fps: {
      type: Number,
      required: false,
      default: 10,
    },
    activatorIcon: {
      type: String,
      required: false,
    },
    dialogWidth: {
      type: Number,
      required: false,
      default: 600,
    },
    manualProcess: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data: () => ({
    dialog: false,
    cameras: [],
    selectedCamera: null,
    qrCode: null,
    qrbox: 350,
    loading: false,
  }),
  mounted() {
    this.qrbox = this.$vuetify.breakpoint.width;
  },
  computed: {
    ...mapGetters("navigation", ["isAppLoading"]),
    ...mapGetters("auth", ["currentUser"]),
  },
  watch: {
    dialog(value) {
      //get camera permission on dialog show
      if (value) {
        this.loading = true;
        this.getCameraPermission();
      } else {
        if (this.qrCode) {
          this.qrCode.stop().catch(() => {
            this.addErrorMessage("Error stopping camera");
          });
        }
      }
    },
  },
  methods: {
    ...mapActions("messages", [
        "addMessage",
        "addErrorMessage",
        "addSuccessMessage"
      ]),
    getCameraPermission() {
      Html5Qrcode.getCameras()
        .then((devices) => {
          this.cameras = devices;
          if (devices && devices.length) {
            //Search for first back camera first
            const backCamera = devices.find(
              (d) => d.label.search(/back/i) >= 0
            );
            if (backCamera) {
              this.selectedCamera = backCamera;
            } else {
              //default to first camera
              this.selectedCamera = devices[0];
            }

            this.qrCode = new Html5Qrcode(/* element id */ "qrScanner", { formatsToSupport: [ Html5QrcodeSupportedFormats.QR_CODE ] });

            this.startScanning();

            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.addErrorMessage("Request camera permission failed");
        });
    },
    startScanning() {
      this.qrCode
        .start(
          this.selectedCamera.id,
          // { facingMode: "environment" },
          {
            fps: this.fps, // Optional frame per seconds for qr code scanning
            // qrbox: this.qrbox // Optional if you want bounded box UI
          },
          (qrCodeMessage) => {
            // do something when code is read
            this.processQrCode(qrCodeMessage);
            this.dialog = false;
          },
          (errorMessage) => {
            // parse error, ignore it.
            console.log(errorMessage);
          }
        )
        .catch(() => {
          this.addErrorMessage("Error starting camera");
        });
    },
    switchCamera(camera) {
      //skip if camera is empty or if switching to current selected camera.
      if (!camera || !camera.id || camera.id == this.selectedCamera.id) {
        return;
      }
      console.log("Switching camera to " + camera.label);

      this.selectedCamera = camera;
      //Stop camera and restart camera using new camera id
      if (this.qrCode) {
        this.qrCode
          .stop()
          .then(() => {
            console.log(`switching to camera ${camera.label}`);
            this.startScanning();
          })
          .catch((error) => {
            console.log(error);
            this.addErrorMessage("Error switching camera");
          });
      }
    },
    processQrCode(qrCodeResult) {
      const isUrl = this.isValidHttpUrl(qrCodeResult);
      if (isUrl) {
        if(!this.manualProcess){
          this.$router.push({ name: "QrDispatcher", params: {url: qrCodeResult} });
        }
        this.$emit("captured", qrCodeResult);
      } else {
        // Decrypt
        const decryptedData = decrypt(qrCodeResult);
        if(!this.manualProcess){
          this.$router.push({ name: "QrDispatcher", params: {data: decryptedData} });
        }
        this.$emit("captured", decryptedData);
      }
    },
    isValidHttpUrl(string) {
      try {
        new URL(string);
        return true;
      } catch (_) {
        return false;
      }
    },
  },
};
</script>
